import {
  faArrowRight, faCheck, faChevronDown, faCrown, faExternalLink, faInfoCircle, faSpinnerThird,
  faTimes, faUnlink, faSync,
} from '@fortawesome/pro-regular-svg-icons';

import { faUsers } from '@fortawesome/pro-solid-svg-icons';
import { faDiscord, faSteam, faSteamSymbol } from '@fortawesome/free-brands-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { createPinia } from 'pinia';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import { App as Application, createApp, markRaw } from 'vue';

import Plausible from 'plausible-tracker';
import App from './App.vue';
import { setAxiosDefaults } from './axios';
import router from './router';

const { enableAutoPageviews } = Plausible();
enableAutoPageviews();

library.add(faArrowRight, faCheck, faChevronDown, faCrown, faDiscord, faExternalLink, faInfoCircle,
  faSpinnerThird, faSteam, faSteamSymbol, faSync, faTimes, faUnlink, faUsers);

const pinia = createPinia().use(({ store }) => {
  // eslint-disable-next-line no-param-reassign
  store.router = markRaw(router);
});

const app: Application = createApp(App)
  .component('fa-icon', FontAwesomeIcon)
  .use(pinia)
  .use(router);

app.mount('#app');

setAxiosDefaults();

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    app,
    dsn: 'https://8b5b18e1dec64c74b05566b6f1da21d0@o1113759.ingest.sentry.io/6144531',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'steamcord.link', /^\//],
      }),
    ],
    tracesSampleRate: 0.5,
  });
}
