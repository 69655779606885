import { defineStore } from 'pinia';
import { useRouter } from 'vue-router';
import { IPlayer, ISteamAccount } from '../entities/player';
import {
  getToken, getDiscordUri, getSteamUri, getTokenFromDiscord, getTokenFromSteam, refreshSteamAccount,
  unlinkAccount, updatePrimaryAccount,
} from '../services/steamcord';

interface IState {
  expiration?: Date;
  player?: IPlayer;
  token?: string;
  stateIDs: string[];
}

function getState() {
  const value = localStorage.getItem('state');
  if (value) {
    try {
      return JSON.parse(value);
    } catch {
      // invalid state
    }
  }

  return {
    expiration: undefined,
    player: undefined,
    token: undefined,
    stateIDs: [],
  } as IState;
}

function saveState(state: IState) {
  localStorage.setItem('state', JSON.stringify({
    expiration: state.expiration,
    player: state.player,
    token: state.token,
    stateIDs: state.stateIDs,
  }));
}

async function redirectFromOAuthState(): Promise<string | undefined> {
  const params = new URLSearchParams(window.location.search);

  const state = params.get('state');
  if (state) {
    const { redirectUri, id } = JSON.parse(state);
    if (redirectUri) {
      if (new URL(redirectUri).host === window.location.host) {
        return undefined;
      }

      window.location.href = `${redirectUri}?${params}`;
      return id;
    }

    const router = useRouter();

    // state has no valid redirectUri
    await router.replace({ name: 'Bad Request' });
  }

  return undefined;
}

export const useTokenStore = defineStore('token', {
  state() {
    return getState();
  },
  actions: {
    async getToken() {
      const response = await getToken();
      if (!response) {
        await this.router.push({ name: 'Not Found' });
        return;
      }

      this.token = response.token;
      this.expiration = response.expirationDate;

      saveState(this);
    },
    async redirectToDiscord(joinGuild: boolean) {
      const response = await getDiscordUri(joinGuild);

      if (!response) {
        await this.router.push({ name: 'Internal Server Error' });
        return;
      }

      this.stateIDs.push(response.stateId);

      saveState(this);

      window.location.href = response.uri;
    },
    async getTokenFromDiscord() {
      redirectFromOAuthState();

      const { expirationDate, player, token } = await getTokenFromDiscord();

      this.expiration = expirationDate;
      this.player = player;
      this.token = token;

      saveState(this);

      await this.router.replace({ name: 'Home' });
    },
    async redirectToSteam() {
      const response = await getSteamUri();
      if (!response) {
        await this.router.push({ name: 'Internal Server Error' });
        return;
      }

      window.location.href = response.uri;
    },
    async getTokenFromSteam() {
      const {
        expirationDate, player, accountId, token,
      } = await getTokenFromSteam();

      this.expiration = expirationDate;
      this.player = player;
      this.token = token;

      saveState(this);

      await this.router.replace({ name: 'Home', query: { steamID: accountId } });
    },
    async refreshSteamAccount(steamID: string) {
      if (!this.player.steamAccounts.some(
        (account: ISteamAccount) => account.steamId === steamID,
      )) {
        return;
      }

      const { expirationDate, player, token } = await refreshSteamAccount(steamID);

      this.expiration = expirationDate;
      this.player = player;

      this.token = token;

      saveState(this);
    },
    async unlinkAccount(provider: string, id: string) {
      const { expirationDate, player, token } = await unlinkAccount(provider, id);

      this.expiration = expirationDate;
      this.player = player;
      this.token = token;

      saveState(this);
    },
    async updatePrimaryAccount(provider: string, id: string) {
      const { expirationDate, player, token } = await updatePrimaryAccount(provider, id);

      player.discordAccounts = player.discordAccounts.sort(
        (account) => (account.isPrimary ? -1 : 1),
      );

      player.steamAccounts = player.steamAccounts.sort(
        (account) => (account.isPrimary ? -1 : 1),
      );

      this.expiration = expirationDate;
      this.player = player;
      this.token = token;

      saveState(this);
    },
  },
  getters: {
    isAuthenticated(): boolean {
      return this.token && new Date(this.expiration) > new Date();
    },
  },
});
