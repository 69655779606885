
import { mapActions } from 'pinia';
import { defineComponent } from 'vue';
import { useTokenStore } from '../store/tokenStore';

export default defineComponent({
  methods: mapActions(useTokenStore, ['getTokenFromDiscord', 'getTokenFromSteam']),
  props: {
    provider: {
      type: String,
      required: true,
    },
  },
  async created() {
    if (this.provider === 'discord') await this.getTokenFromDiscord();
    else if (this.provider === 'steam') await this.getTokenFromSteam();
  },
});
