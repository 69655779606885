
import { mapState } from 'pinia';
import { defineComponent } from 'vue';
import { useMainStore } from './store/mainStore';
import { useOrgStore } from './store/orgStore';
import AppHeader from './components/AppHeader.vue';
import ExternalAnchor from './components/ExternalAnchor.vue';
import Spinner from './components/Spinner.vue';

export default defineComponent({
  components: {
    AppHeader,
    Spinner,
    ExternalAnchor,
  },
  computed: {
    ...mapState(useMainStore, ['loading']),
    ...mapState(useOrgStore, ['name', 'settings']),
  },
});
