import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = {
  key: 0,
  class: "header__logo-container"
}
const _hoisted_3 = ["alt", "src"]
const _hoisted_4 = {
  key: 1,
  class: "header__name"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    (_ctx.imageUri)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("img", {
            class: "header__logo",
            alt: _ctx.name,
            src: _ctx.imageUri
          }, null, 8, _hoisted_3)
        ]))
      : (_openBlock(), _createElementBlock("h1", _hoisted_4, _toDisplayString(_ctx.name), 1))
  ]))
}