import axios from 'axios';
import { IPlayer } from '../entities/player';
import { ISettings } from '../entities/settings';

export interface IOrganization {
  id: number;
  name: string;
  isProPlan: boolean;
  settings: ISettings;
}

export async function getOrg(): Promise<IOrganization | undefined> {
  try {
    const { data: response } = await axios.get<IOrganization>('/orgs', {
      params: {
        hostname: window.location.host,
      },
    });

    return response;
  } catch (err) {
    return undefined;
  }
}

export interface ITokenResponse {
  expirationDate: Date;
  player: IPlayer;
  accountId: string | undefined;
  token: string;
}

export async function getToken(): Promise<ITokenResponse | undefined> {
  const { data: response } = await axios.post<ITokenResponse>('/authorization', {
    hostname: window.location.host,
  });

  return response;
}

export interface IURIResponse {
  uri: string;
}

export interface IOAuthURIResponse extends IURIResponse {
  stateId: string;
}

export async function getDiscordUri(joinGuild: boolean): Promise<IOAuthURIResponse | undefined> {
  const { data: response } = await axios.get<IOAuthURIResponse>('/discord', {
    params: {
      redirectUri: `https://${window.location.host}/discord/redirect`,
      joinGuild,
    },
  });

  return response;
}

export async function getSteamUri(): Promise<IURIResponse | undefined> {
  const { data: response } = await axios.get<IURIResponse>('/steam', {
    params: {
      redirectUri: `https://${window.location.host}/steam/redirect`,
    },
  });

  return response;
}

async function getTokenFromRedirect(route: string): Promise<ITokenResponse> {
  const { data: response } = await axios.post<ITokenResponse>(`${route}${window.location.search}`, {
    hostname: window.location.host,
  });

  return response;
}

export function getTokenFromDiscord(): Promise<ITokenResponse> {
  return getTokenFromRedirect('/discord/token');
}

export function getTokenFromSteam(): Promise<ITokenResponse> {
  return getTokenFromRedirect('/steam/token');
}

export async function refreshSteamAccount(steamID: string): Promise<ITokenResponse> {
  const { data: response } = await axios.post<ITokenResponse>(`/steam/refresh/${steamID}`);

  return response;
}

export async function unlinkAccount(provider: string, id: string): Promise<ITokenResponse> {
  const { data: response } = await axios.delete<ITokenResponse>(`/${provider}/${id}`);

  return response;
}

export async function updatePrimaryAccount(provider: string, id: string): Promise<ITokenResponse> {
  const { data: response } = await axios.put<ITokenResponse>(`/${provider}/primary`, { id });

  return response;
}
