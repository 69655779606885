import axios from 'axios';
import router from './router';
import { useTokenStore } from './store/tokenStore';

export function setAxiosDefaults(): void {
  axios.defaults.baseURL = process.env.VUE_APP_BASE_URI;

  axios.interceptors.request.use((config) => {
    if (config.headers) {
      const tokenStore = useTokenStore();
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${tokenStore.token}`;
    }

    return config;
  });

  axios.interceptors.response.use((res) => res, (err) => {
    if (err.response?.status === 400) {
      router.push({ name: 'Bad Request' });
    }

    if (err.response?.status === 401) {
      // store.dispatch('deleteToken');
    }

    if ([401, 403].includes(err.response?.status)) {
      localStorage.removeItem('state');
      window.location.reload();
    }

    if (err.response?.status === 429) {
      router.push({ name: 'Rate Limit' });
    }

    if (err.response?.status === 503) {
      router.push({ name: 'Service Unavailable' });
    } else if (err.response?.status >= 500) {
      router.push({ name: 'Internal Server Error' });
    }

    return Promise.reject(err);
  });
}
