
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    showIcon: {
      default: true,
      type: Boolean,
    },
  },
});
