import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { INVALID_HOSTNAMES } from '../consts';
import Redirect from '@/views/Redirect.vue';
import Error from '@/views/Error.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/discord/redirect',
    component: Redirect,
    props: { provider: 'discord' },
    meta: { authorize: true },
  },
  {
    path: '/steam/redirect',
    component: Redirect,
    props: { provider: 'steam' },
    meta: { authorize: true },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Not Found',
    component: Error,
    props: {
      status: 404,
      title: 'Page Not Found',
      text: 'We couldn\'t find the page you\'re looking for.',
    },
  },
  {
    path: '/400',
    name: 'Bad Request',
    component: Error,
    props: {
      status: 400,
      title: 'Bad Request',
      text: 'Please try again later.',
    },
  },
  {
    path: '/429',
    name: 'Rate Limit',
    component: Error,
    props: {
      status: 429,
      title: 'You\'re Doing That Too Fast',
      text: 'Please try again later.',
    },
  },
  {
    path: '/500',
    name: 'Internal Server Error',
    component: Error,
    props: {
      status: 500,
      title: 'Internal Server Error',
      text: 'Please try again later.',
    },
  },
  {
    path: '/503',
    name: 'Service Unavailable',
    component: Error,
    props: {
      status: 503,
      title: 'Service Unavailable',
      text: 'Steam could not be reached, please try again later.',
    },
  },
];

const sharedRouteData = {
  path: '/',
  name: 'Home',
  props: true,
};

const subdomain = window.location.hostname.split('.')[0];
if (subdomain === 'demo') {
  routes.push({
    ...sharedRouteData,
    component: () => import('@/views/custom/Demo.vue'),
  });
} else if (subdomain === 'acerust' || window.location.hostname.endsWith('acerust.com')) {
  routes.push({
    ...sharedRouteData,
    component: () => import('@/views/custom/AceRust.vue'),
    meta: { showHeader: true },
  });
} else if (subdomain === 'project-nova') {
  routes.push({
    ...sharedRouteData,
    component: () => import('@/views/custom/ProjectNova.vue'),
    meta: { showHeader: true },
  });
} else if (subdomain === 'chillvibez' || window.location.hostname.endsWith('chillvibez.net')) {
  routes.push({
    ...sharedRouteData,
    component: () => import('@/views/custom/ChillVibez.vue'),
  });
} else if (window.location.hostname.endsWith('theprojectlazarus.com')) {
  routes.push({
    path: '/',
    name: 'Home',
    props: true,
    meta: { hideFooter: true },
    component: () => import('@/views/custom/ProjectLazarus.vue'),
  });
} else if (subdomain === 'militarymotives' || window.location.hostname.endsWith('militarymotives.io')) {
  routes.push({
    ...sharedRouteData,
    component: () => import('@/views/custom/MilitaryMotives.vue'),
    meta: { showHeader: true },
  });
} else if (subdomain === 'rustinpeace' || window.location.hostname.endsWith('rustinpeace.co')) {
  routes.push({
    ...sharedRouteData,
    component: () => import('@/views/custom/RustInPeace.vue'),
    meta: { showHeader: true },
  });
} else if (window.location.hostname.endsWith('legacyrust.co')) {
  routes.push({
    ...sharedRouteData,
    component: () => import('@/views/custom/Legacy.vue'),
  });
} else if (subdomain === 'equalityrust') {
  routes.push({
    ...sharedRouteData,
    component: () => import('@/views/custom/EqualityRust.vue'),
    meta: { showHeader: true },
  });
} else if (subdomain === 'nomads' || window.location.hostname.endsWith('amnomad.vip')) {
  routes.push({
    ...sharedRouteData,
    component: () => import('@/views/custom/Nomads.vue'),
    meta: { showHeader: true },
  });
} else if (window.location.hostname.endsWith('link.icyrust.net')) {
  routes.push({
    ...sharedRouteData,
    component: () => import('@/views/custom/IcyRust.vue'),
    meta: { showHeader: true, hideFooter: true },
  });
} else if (subdomain === 'donk' || window.location.hostname.endsWith('link.donkgaming.com')) {
  routes.push({
    ...sharedRouteData,
    component: () => import('@/views/custom/Donk.vue'),
    meta: { showHeader: true },
  });
} else if (INVALID_HOSTNAMES.includes(window.location.hostname)) {
  routes.push({
    ...sharedRouteData,
    component: () => import('@/views/HomeRedirect.vue'),
  });
} else {
  routes.push({
    ...sharedRouteData,
    component: () => import('@/views/Home.vue'),
    meta: { showHeader: true },
  });
}

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (['/discord/redirect'].includes(to.path)) {
    next();
    return;
  }

  const slug = to.path.slice(1);
  if (to.name !== 'Not Found' || slug.includes('/')) {
    next();
    return;
  }

  if (['localhost', 'steamcord.link'].includes(window.location.hostname)) {
    window.location.href = `${window.location.protocol}//${slug}.${window.location.host}`;
    return;
  }

  next();
});

export default router;
