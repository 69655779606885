import { defineStore } from 'pinia';
import { ISettings } from '../entities/settings';
import { getOrg } from '../services/steamcord';

export const useOrgStore = defineStore('org', {
  state() {
    return {
      id: undefined as number | undefined,
      isProPlan: false,
      name: '',
      settings: undefined as ISettings | undefined,
    };
  },
  actions: {
    async getOrg() {
      const org = await getOrg();
      if (!org) {
        this.router.push({ name: 'Not Found' });
        return;
      }

      this.id = org.id;
      this.name = org.name;
      this.isProPlan = org.isProPlan;
      this.settings = org.settings;

      if (!this.isProPlan) {
        return;
      }

      if (this.settings.title) {
        window.document.title = this.settings.title;
      }

      if (this.settings.faviconUri) {
        const link = window.document.querySelector('link[rel*="icon"]') as HTMLLinkElement;
        if (link) {
          link.href = this.settings.faviconUri;
        }
      }
    },
  },
});
